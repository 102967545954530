import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './StoreMapping.css';
import { useStoreService } from '../..//services';
import {
    Button,
    Form,
    Col,
    Row,
    Input,
    Table,
    Space,
    Modal,
    Tag
} from 'antd';
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DeleteFilled,
    PlusCircleFilled
} from '@ant-design/icons';
import Select from "react-select";
import { Empty } from 'antd';
import { Notification } from '../../utilities/notification';
import locationIcon from '../../assets/icons/svg/locationIcon.svg';
import shopifyIcon from '../../assets/icons/svg/shopifyIcon.svg';
import store from "../../redux/store";
const { Search } = Input;
const LocationIcon = () => <img src={locationIcon} alt="locationIcon" />;
const ShopifyIcon = () => <img src={shopifyIcon} alt="shopifyIcon" />;

//Store Mapping

const StoreMapping = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [tabledata, setTabledata] = useState([]);
    const [storeSelected, setStoreSelected] = useState({});
    const storeService = useStoreService();
    const [loadingStores, setLoadingStores] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [isConnectingStore, setIsConnectingStore] = useState(false);
    const [store_sync_id, setstore_sync_id] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [deleteModal, setDeleteModal] = useState(null);
    const [loading, setLoading] = useState(false);

    const columns = [
        // {
        //     title: 'Platform',
        //     dataIndex: 'platform',
        //     key: 'platform',
        //     render: (platform, record) => (
        //         <Space size="middle">
        //             {"Shopify"}
        //         </Space>
        //     )
        // },
    
        {
            title: 'Store URL',
            dataIndex: 'store_url',
            key: 'store_url'
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'status',
            render: (_, record) => (
                <Space size="middle">
                        <DeleteFilled
                            style={{
                                color: "#32ADFFFF",
                                fontSize: 20
                            }}
                            onClick={async () => {
                                setDeleteModal(record);
                            }}
                        />
                </Space>
            )
        }
    ];

    useEffect(() => {
        (async () => {
            setLoadingStores(true);
            const current_store_details = storeService.getCurrentStoreDetails();
            setStoreSelected(current_store_details)
            await handleStoreChange(current_store_details)
            console.log(current_store_details, "store_selected")
            setLoadingStores(false);
        })();
    }, []);

    const handleStoreChange = async ( current_store_details) => {
        try {
            setLoadingTable(true);
            let responseStoreData = [];
            const response = await storeService.getAllMappedStores({
                store_sync_id: current_store_details?.store_sync_id,
                type: current_store_details.type
            });

            responseStoreData = response.data;
            console.log(responseStoreData);

            setTotalRecords(responseStoreData?.length);
            let processedData = responseStoreData?.map((item) => {
                return {
                    key: item.user_id,
                    store_url: item.store_url,
                    store_sync_id: item.store_sync_id,
                    status: [item.status]
                }
            });
            setLoadingTable(false);
            setTabledata(processedData);
        } catch (error) {
            console.log(error);
            setLoadingTable(false);
            setTabledata([]);
        }
    };

    const connectStore = async () => {
        let request = {};
        console.log(storeSelected, "connect store payload");
        if (storeSelected.type === 'source') {
            request = {
                source_sync_id: storeSelected.store_sync_id,
                destination_sync_id: store_sync_id
            }
        } else {
            request = {
                source_sync_id: store_sync_id,
                destination_sync_id: storeSelected.store_sync_id
            }
        }
        console.log(request);
        setIsConnectingStore(true);
        try {
            const response = await storeService.addStoreMap(request);
            setIsConnectingStore(false);
            setModalOpen(false);
            setstore_sync_id('');
            if (response) {
                Notification('success', 'Store connected successfully.');
            } else {
                Notification(
                    'error',
                    response?.msg || 'Error while connecting new store. Please try again later.'
                );
            }
        } catch (err) {
            Notification('error', 'Something went wrong while connecting store.');
            console.log(err);
        }
        setCurrentPage(1);
        await handleStoreChange(storeSelected);
    }

    const deleteStoreMap = async () => {
        setLoading(true);
        try {
            if(storeSelected?.type === 'source'){

            }
            const response =
                storeSelected?.type === 'source' ?
                    await storeService.deleteStoreMapping({ source_sync_id: storeSelected?.store_sync_id, destination_sync_id: deleteModal?.store_sync_id }) :
                    await storeService.deleteStoreMapping({ source_sync_id: deleteModal?.store_sync_id, destination_sync_id: storeSelected?.store_sync_id });
            
            if (response.data) {
                Notification('success', 'Store mapping deleted successfully.');
            } else {
                Notification(
                    'error',
                    'Error while deleting store mapping. Please try again later.'
                );
            }
        } catch (err) {
            Notification('error', 'Something went wrong while connecting store.');
            console.log(err);
        }
        setCurrentPage(1);
        await handleStoreChange(storeSelected);
        setLoading(false);
        setDeleteModal(null)
    }

    return (
        <div fallback={<p>Something went wrong</p>}>
            <div className='content_head_ui'>
                <h2 className="heading">Store Mappings </h2>
                {
                    storeSelected?.type === 'destination' &&
                    <Button
                        id="connectButton"
                        onClick={() => setModalOpen(true)}
                        type="primary"
                        className='primary_btn'
                        icon={<PlusCircleFilled/>}
                    >
                        {`Connect New ${storeSelected?.type === 'source' ? 'Destination' : 'Source'} Store`}
                    </Button>
                }
            </div>


            <Modal
                width={700}
                title={`Connect to new ${storeSelected?.type === 'source' ? 'destination' : 'source'} store`}
                style={{
                    textAlign: 'left'
                }}
                centered
                open={modalOpen}
                okText="Add Store"
                onOk={connectStore}
                onCancel={() => setModalOpen(false)}
                cancelButtonProps={{ disabled: isConnectingStore }}
                okButtonProps={{ disabled: store_sync_id === '' || isConnectingStore, loading: isConnectingStore }}
            >
                <Form className='w-100 custom-form-wrap' layout="vertical" style={{ marginTop: 32 }}>
                            <Form.Item label="Enter StoreSync Key" className='form-label'>
                                <Input className='custom-input' placeholder="Enter StoreSync Key" value={store_sync_id} onChange={(e) => setstore_sync_id(e.target.value)} />
                            </Form.Item>
                </Form>
            </Modal>

            <Modal
                open={deleteModal}
                title="Confirmation"
                onCancel={() => setDeleteModal(null)}
                footer={[
                    <Button onClick={() => setDeleteModal(null)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={async () => await deleteStoreMap()}>
                        Yes
                    </Button>
                ]}
            >
                <p>Are you sure you want to delete these store mapping ?</p>
            </Modal>

            {
                tabledata.length !== 0 ?
                    <>

                        <Table
                            className="storeMappingTable custom-table-wrap "
                            columns={columns}
                            dataSource={tabledata}
                            loading={loadingTable}
                            pagination={
                                {
                                    current: currentPage,
                                    pageSize: 10,
                                    total: totalRecords,
                                    onChange: async (page) => {
                                        setCurrentPage(page);
                                        // await handleStoreChange(storeSelected?.store_sync_id, searchText, page);
                                    }
                                    
                                }
                            }
                        />
                    </> :
                    <p
                        className='empty_wrapper'
                        style={{
                        }}
                    >
                        <div><Empty 
                        image={Empty.PRESENTED_IMAGE_SIMPLE} 
                        description={
                            <span>
                              {storeSelected ? 'No store mapping found' : 'Please connect a store'}
                            </span>
                          }
                        /></div>
                    </p>
            }
        </div>
    );
};
export default StoreMapping;
